<template>
  <div v-if="menuUrl" id="menuUrl">
    <iframe :src="menuUrl" id="iframe"></iframe>
  </div>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
export default {
  data () {
    return {
      menuUrl: null
    };
  },
  mounted () {
    this.menuUrl = 'https://qr.precompro.com?vendor=' + this.vendor.url;
    this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
  }
};
</script>
<style scoped lang="scss">
  #menuUrl {
    width: 100%;
    height: 100%;
    #iframe {
      width: 100%;
      height: 100%;
    }
  }
</style>
<style>
  html, body, #app {
    width: 100%;
    height: 100%;
  }
</style>
